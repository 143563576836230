@keyframes JoinLetter {
	0% {
		opacity: 0;
		letter-spacing: 1.6rem;
	}

	100% {
		opacity: 1;
		letter-spacing: 0.2rem;
	}
}

@mixin itemsDelay($number, $value) {
	@for $i from 1 to ($number + 1) {
		&:nth-of-type(#{$i}) {
			animation-delay: (#{$i * $value});
			@content;
		}
	}
}
