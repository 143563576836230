@import 'base/colors';
@import 'base/fonts';
@import 'base/media';
@import 'base/mixin';
@import 'base/fonts';
@import 'base/animate';

@import './base/export';

.fa-btn {
	border: 1px solid;
	@apply rounded-full py-1.5 px-4 text-sm font-normal
	 border-secondary border-opacity-10;
	.fa-icon {
		@apply w-5;
	}
}

.loading-cover {
	min-height: 300px;

	.rs-loader {
		.rs-loader-spin,
		.rs-loader-spin::before,
		.rs-loader-spin::after {
			width: 100px;
			height: 100px;
		}

		.rs-loader-spin {
			&::after {
				//? Moving part
				border-top-color: rgba($primary, 0.9);
				border-top-width: 4px;
				// box-shadow: 0 -15px 30px -12px rgba(0, 0, 0, 0.2);
			}
			&::before {
				//? Bg part
				@apply opacity-10;
				border-color: rgba($color: white, $alpha: 0.8) !important;
			}
		}
	}
}

.panel-loader-wrapper {
	@apply animate-fade-in;
}

.rs-panel-group .rs-panel + .rs-panel:before {
	@apply border-0;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
	box-shadow: none !important;
}
