$primary: #edbc7c;
$secondary: #2d2d2d;
$tertiary: #fddd48;

$red: #ff3b3b;
$green: #06c270;
$blue: #0063f7;
$orange: #ff8800;
$yellow: #ffcc00;
$cyan: #00cfde;
$purple: #6600cc;
$gray: #9aa6ac;

$red-light: #ff5c5c;
$green-light: #39d98a;
$blue-light: #5b8def;
$orange-light: #fdac42;
$yellow-light: #fddd48;
$cyan-light: #73dfe7;
$purple-light: #ac5dd9;
$gray-light: #e5e9eb;

$red-dark: #ff3b3b;
$green-dark: #06c270;
$blue-dark: #0063f7;
$orange-dark: #ff8800;
$yellow-dark: #ffcc00;
$cyan-dark: #00cfde;
$purple-dark: #6600cc;
$gray-dark: #84909a;

$white: #ffffff;
$black: #000000;

$magenta: #b1176a;

$green-emerald: #27626a;

$light: #fbfbfb;
$dark: #212122;

$success: #50cb93;
$error: #ff3b3b;
